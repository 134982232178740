import React, {useState, useEffect} from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
// import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
import {navigate} from "gatsby"
import SEO from "../components/seo.js"
import Loader from '../components/WidgetComponents/Loader'
import Layout from "../components/layout"
import * as typeformEmbed from '@typeform/embed'
// import WaitlistAppBar from '../components/WidgetComponents/WaitlistAppBar.js'
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar'
import planeAnimation from '../images/paper-plane.gif';
import MuiAlert from '@material-ui/lab/Alert';


if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

function Login(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [innerHeight, setInnerHeight] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  function openForm() {
    typeformEmbed.makePopup('https://solg.typeform.com/to/S6qaIN', {
      // mode: 'drawer_left',
      autoOpen: true,
      // autoClose: 3,
      hideScrollbars: true,
      onSubmit: function() {
        console.log('Typeform successfully submitted')
      }
    })
  }

  function handleSignOut() {
    // firebase
    //   .auth()
    //   .signOut();
    navigate('/')
  }

  useEffect(() => {
    if (!isLoaded) {
      if (typeof window !== `undefined`) {
        setInnerHeight(window.innerHeight)
        setIsLoaded(true)
      }
    }
  }, [isLoaded]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props}/>;
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  let snackbar = (<Snackbar open={snackbarOpen} autoHideDuration={1000} onClose={closeSnackbar}>
    <Alert onClose={closeSnackbar} style={{
        backgroundColor: '#4db884'
      }}>
      Success, link copied!
    </Alert>
  </Snackbar>)

  let shareUrl = 'https://quickstyle.app'

  let mailToArray = [
    'mailto:?subject=',
    'Check out QuickStyle.app',
    '&body=',
    'Check out QuickStyle (',
    shareUrl,
    "). It's an easy way to make a great brand style guide."
  ]
  let mailToLink = mailToArray.join('')

  function copyToClipboard(event, text) {
    navigator
      .clipboard
      .writeText(text)
      .then(function() {
        setSnackbarOpen(true);
      }, function() {
        /* clipboard write failed */
      });
  }

  const handleShareDialogOpen = () => {
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  let shareDialog = (<Dialog open={shareDialogOpen} onClose={handleShareDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogContent style={{
        margin: '10px 30px 30px'
      }}>
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: ''
        }}>
        <Grid container="container" direction="column" justify="center" alignItems="center" style={{
            backgroundColor: ''
          }}>
          <img id='mockup' alt='Product Mockup' src={planeAnimation} style={{
              objectFit: 'cover',
              width: '200px',
              height: '100px'
              // backgroundColor: 'red'
            }}/>
        </Grid>
        <Typography id="share-dialog" style={{
            textAlign: "center",
            fontSize: '35px',
            fontWeight: 'bold',
            fontFamily: 'Work Sans',
            margin: '-5px 0px 5px 0px',
            lineHeight: 1
          }}>Share QuickStyle</Typography>
        <Grid container="container" direction="row" justify="center" alignItems="center" style={{
            paddingTop: '15px'
          }}>
          <Button variant='outlined' color='secondary' onClick={event => copyToClipboard(event, shareUrl)} style={{
              // color: 'grey',
              margin: '5px 10px 10px'
            }}>Copy Link</Button>
          <Button variant='outlined' color='secondary' onClick={event => window.open(mailToLink)} style={{
              // color: 'grey',
              margin: '5px 10px 10px'
            }}>Send in Email</Button>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>)

  const page = (<Layout>
    <SEO title='Requested'/>
    <Grow in="in">
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: '',
          margin: '0px',
          minHeight: '100vh',
          // width: '100vw',
          padding: '0px 30px'
        }}>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'bold',
            fontSize: '35px',
            paddingBottom: '30px',
            textAlign: 'center',
            lineHeight: 1.2
          }}>We've got you on the list. {<span role="img" aria-label="awkward">🎉</span>}</Typography>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'normal',
            fontSize: '22px',
            paddingBottom: '20px',
            textAlign: 'center',
            lineHeight: 1.3,
            maxWidth: '800px'
          }}>{"Access to QuickStyle is currently by invitation only. If you receive an invite, go ahead and hit the link in the email. Otherwise, stay tuned. We'll be in touch soon."}</Typography>
        <Grid container="container" direction="row" wrap="wrap-reverse" justify="center" alignItems="center" style={{
            backgroundColor: '',
            padding: '5px'
          }}>
          <Button color='' variant='outlined' size='large' style={{
              color: 'grey',
              margin: '10px'
            }} onClick={handleSignOut}>Back Home</Button>
          <Button color='secondary' variant='contained' size='large' onClick={handleShareDialogOpen} style={{
              // color: 'grey',
              margin: '10px'
            }}>Share with a Friend</Button>
        </Grid>
      </Grid>
    </Grow>
    {shareDialog}
    {snackbar}
  </Layout>)

  return (
    isLoaded
    ? page
    : <Loader/>)
}

export default Login

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
